import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import variables from "../styles/variables"
import breakpoints from "../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

const PageContainer = styled.div`
  background-color: ${variables.colorBlackFG};
  color: ${variables.colorWhiteFG};
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 2.25em;
    padding-right: 2.25em;
  }
`

const Button = styled.button`
  text-decoration: ${(props) => (props.active ? "underline" : "none")};
  text-underline-position: under;
  margin-right: 1.2em;

  :hover {
    text-decoration: underline;
  }
`

const TextAreaContainer = styled.div`
  margin-top: 96px;
`

const TextArea = styled.div`
  text-align: ${(props) => (props.center ? "center" : "left")};
  margin-bottom: 256px;

  @media (min-width: ${breakpoints.lgw}) {
    max-width: 618px;
    margin-left: auto;
    margin-right: auto;
    margin: ${(props) => (props.bio ? "0" : "0 auto 256px")};
  }

  @media (min-width: ${breakpoints.xl}) {
    max-width: 528px;
  }

  p {
    margin-bottom: 1em;
    font-family: "ITC Century W01 Book", serif;

    @media (min-width: ${breakpoints.lgw}) {
      margin-right: 1em;
    }
  }

  hr {
    display: block;
    opacity: 0;
    margin: 1rem;
    height: 0.14em;
  }

  i {
    font-style: italic;
  }

  a {
    text-underline-position: under;
    text-decoration: underline;

    :hover {
      text-decoration: none;
    }
  }
`

const BioGrid = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 256px;
  flex-direction: column;
  gap: 40px;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    gap: 60px;
  }
`

const ImageWrapper = styled.div`
  min-width: 350px;
  @media (min-width: ${breakpoints.md}) {
    max-width: 400px;
  }
`

const Title = styled.h2`
  /* font-family: "ITC Century W01 Book", serif; */
  font-size: 28px;
  margin-bottom: 20px;
`

const MobileTitle = styled(Title)`
  display: block;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const DesktopTitle = styled(Title)`
  display: none;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const AboutPage = ({ data, location }) => {
  const path = location.pathname

  const { bio, awards, tcs } = data

  const pageTitle = "About"

  const parsedRawDescription = JSON.parse(bio.description.raw)
  let metaDescription = parsedRawDescription.content[0]?.content[0]?.value

  if (!metaDescription) {
    metaDescription =
      pageTitle +
      " - Greg White photographer. Represented by Picture Club. Award winning for his commercial and personal projects, specialising in landscape, technology and automotive photography."
  } else if (metaDescription.length > 255) {
    metaDescription = metaDescription.slice(0, 253) + "..."
  }

  const [id, setId] = React.useState("bio")

  const awardsOptions = {
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  const getTextArea = (id) => {
    if (id === "bio") {
      return (
        <BioGrid>
          <div>
            <MobileTitle>{bio.contentTitle}</MobileTitle>
            <ImageWrapper>
              <GatsbyImage image={bio.image.gatsbyImageData} alt="Greg White" />
            </ImageWrapper>
          </div>
            <TextArea bio>
              <DesktopTitle>{bio.contentTitle}</DesktopTitle>
              {renderRichText(bio.content)}
            </TextArea>
        </BioGrid>
      )
    }
    if (id === "awards") {
      return (
        <div>
          <TextArea center={true}>
            {awards.contentTitle && <Title>{awards.contentTitle}</Title>}
            {renderRichText(awards.content, awardsOptions)}
          </TextArea>
        </div>
      )
    }
    if (id === "t-c") {
      return (
        <TextArea>
          {tcs.contentTitle && <Title>{tcs.contentTitle}</Title>}
          {renderRichText(tcs.content)}
        </TextArea>
      )
    }
    return null
  }

  const handleClick = (e) => {
    setId(e.target.dataset.target)
  }

  return (
    <Layout theme={"dark"}>
      <Seo
        fullTitle={false}
        path={path}
        title={pageTitle}
        description={metaDescription}
      />
      <PageContainer>
        <Button
          data-target="bio"
          onClick={(e) => handleClick(e)}
          active={id === "bio"}
        >
          Biography
        </Button>
        <Button
          data-target="awards"
          onClick={(e) => handleClick(e)}
          active={id === "awards"}
        >
          Awards
        </Button>
        <Button
          data-target="t-c"
          onClick={(e) => handleClick(e)}
          active={id === "t-c"}
        >
          T&Cs
        </Button>
        <TextAreaContainer>{getTextArea(id)}</TextAreaContainer>
      </PageContainer>
    </Layout>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query {
    bio: contentfulTextPage(pageTitle: { eq: "Biography" }) {
      contentTitle
      image {
        gatsbyImageData
      }
      content {
        raw
      }
      description {
        raw
      }
    }
    awards: contentfulTextPage(pageTitle: { eq: "Awards" }) {
      contentTitle
      content {
        raw
      }
    }
    tcs: contentfulTextPage(pageTitle: { eq: "T&Cs" }) {
      contentTitle
      content {
        raw
      }
    }
  }
`
